import { React, useState} from "react";
import { Box,Flex, Button} from '@chakra-ui/react';
import TaskTab from 'views/user/property-management/view/components/task';
import NoteTab from 'views/user/property-management/view/components/note';
import ReservationTab from 'views/user/property-management/view/components/reservation';
import CityTaxTab from 'views/user/property-management/view/components/city-tax';
import { userController } from 'lib/user';

function PropertyBoard() {

    const [propertypage, setPropertyPage] = useState('task');
    const {userDetail} = userController();
    const { getUser } = userDetail();

    const advanceGroup  = ["admin","office"];

    const PropertyPages = {
        "task": {"title": "Tasks", "show":true, "content": <TaskTab title="Task List" />},
        "reservation": {"title": "Reservations", "show":advanceGroup.concat(["operation"]).includes(getUser.role), "content": <ReservationTab title="Reservation List" />},
        "city-tax": {"title": "City Tax", "show":advanceGroup.includes(getUser.role), "content": <CityTaxTab title="City Tax List" />},
        "notes": {"title": "Notes", "show":advanceGroup.concat(["operation"]).includes(getUser.role), "content": <NoteTab title="Note List" Datatype="note" />},
        "instructions": {"title": "Instructions", "show":advanceGroup.concat(["operation"]).includes(getUser.role), "content": <NoteTab title="Instruction List" Datatype="instruction" />}
    };

    const visibleTabs = Object.keys(PropertyPages).filter(key => PropertyPages[key].show);

    const handlePageChange = (page) => {
        setPropertyPage(page);
    }
    return (
        <Box  pt={{ base: "130px", md: "80px", xl: "80px" }}>
            {visibleTabs.length > 1 && (
                <Box
                mt={5}
                >
                    <Flex
                        className='tab-list'
                        flexDirection={{ base: "column", md: "row" }}
                        flex={{ base: "1", md: "0" }}
                    >
                        {Object.keys(PropertyPages).map((key, index) =>
                            PropertyPages[key].show && (
                                <Button
                                onClick={() => handlePageChange(key)}
                                variant={propertypage === key ? "solid" : "outline"}
                                colorScheme="blue"
                                className={"tab-title "+(propertypage === key ? "active" : "")}
                                width="100%"
                                _focus={{ boxShadow: "none" }}
                                key={"tab_title_"+index}
                                >
                                    {PropertyPages[key].title}
                                </Button>
                            )
                        )}
                    </Flex>
                </Box>
            )}
            <Box
            className="tab-content"
            >
                {propertypage &&
                    PropertyPages[propertypage].content
                }
            </Box>
        </Box>
    );
}
export default PropertyBoard;
