import {
  Flex,
  Text,
  FormControl,
  Select,
  Box,
  Icon,
  Button,
  InputRightElement,
  InputGroup,
  Input,
  Checkbox,
  InputLeftElement,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  MdOutlineSearch,
  MdOutlineLoop,
  MdOutlineManageSearch,
  MdPersonSearch,
  MdClose,
} from "react-icons/md";
import BeatLoader from "react-spinners/BeatLoader";
import axios from "axios";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { userController } from 'lib/user';

// Custom components
import Card from "components/card/Card";
import TableMenu from "views/global/TableMenu";
import { Link } from "react-router-dom";

export default function BoxDataTable(props) {
  //PROPS
  const {
    src,
    title,
    menuItems,
    buttons,
    search,
    checkbox,
    checkboxMenu,
    tableMenu,
    external,
    additionalOptions,
    trigger,
    design,
    dragableType,
    tableContent,
    defaultCalander,
    SourceType,
  } = props;

  const {saveRecord} = userController();

  //STATES
  const [currentPage, setCurrentPage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [tableData, setTableData] = useState({
    filters: [],
    rows: {
      data: [],
      links: [],
      total: 0,
    },
  });
  const [contentFilter, setContentFilter] = useState(
    { calander_filter: defaultCalander },
  );
  const [searchFilter, setSearchFilter] = useState([]);
  const [contentLoading, setContentLoading] = useState(false);
  const [checked, setChecked] = useState({});
  const [unchecked, setUnchecked] = useState(false);
  const [perPage, setPerPage] = useState(10);

  //VARIABLES
  const getToken = JSON.parse(localStorage.getItem("token"));
  const alignText = title ? null : "flex-end";
  const checkboxAction = Object.keys(checked).filter(
    (key) => checked[key] === true
  ).length;
  const totalCount = tableData.rows.total;

  //GENERATE EXTERNAL LINKS
  const externalLinkGenerator = (link, type) => {
    const matches = link.match(/\[(.*?)\]/g);
    matches.map((match) => {
      const key = match.replace("[", "").replace("]", "");
      link = link.replace(match, type[key]);
    });
    return link;
  };

  //GENERATE SPECIAL CLASS
  const generateClass = (string) => {
    if (string === null) return null;
    if (!isNaN(string)) return "data-table-single-integer";
    const sanitizedValue = string.replace(/[^a-zA-Z\s]/g, ""); // Keep only alphabetic characters and spaces
    const classes =
      "data-table-single-" + sanitizedValue.split(" ")[0].toLowerCase();
    return classes;
  };

  //GET DATA FROM API
  const getContactList = () => {
    setContentLoading(true);
    setChecked({});
    return axios
      .get(process.env.REACT_APP_API + src, {
        params: {
          perPage: perPage,
          page: currentPage,
          search: searchKeyword,
          ...additionalOptions,
          ...contentFilter,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${getToken}`,
        },
      })
      .then((response) => {
        return response;
      })
      .then((response) => {
        setTableData(response.data);
        setContentLoading(false);
      })
      .catch(function (error) {
        setContentLoading(false);
      });
  };

  //MOUNT DATA EVERYTIME STATE CHANGES
  useEffect(() => {
    getContactList();
  }, [currentPage, perPage, searchKeyword, trigger, contentFilter]);

  //HANDLER TO CHECKED BOXES
  const handleCheckboxChange = (status, id) => {
    setChecked((prev) => ({
      ...prev,
      [id]: status,
    }));
  };

  //HANDLER TO CLEAR CHECKED BOXES
  const handleClear = () => {
    return () => {
      setUnchecked(true);
    };
  };

  useEffect(() => {
    tableData.rows.data.map((row) => {
      row.checked = false;
      handleCheckboxChange(false, row.id);
    });
    setUnchecked(false);
  }, [unchecked]);

  //HANDLE SEARCH BAR
  const handleSearch = (keyword) => {
    if (keyword.length >= 3) {
      setSearchKeyword(keyword);
    } else if (keyword.length <= 0 && searchKeyword !== null) {
      setSearchKeyword(null);
    }
  };

  //HANDLE PAGE CHANGE
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  //HANDLE PER PAGE CHANGE
  const handlePerPageChange = (perPage) => {
    setPerPage(perPage);
    const MaxPage = Math.ceil(totalCount / perPage);
    if (MaxPage < currentPage) {
      setCurrentPage(MaxPage);
    }
  };

  //HANDLE FILTER CHANGE
  const handleFilterChange = (event) => {
    const { name, value } = event.target;
  
    setContentFilter((prev) => {
      const newFilter = { ...prev };
      if (value === "") {
        delete newFilter[name];
      } else {
        newFilter[name] = value;
      }
      return newFilter;
    });
  
    setCurrentPage(1);
  };

  const handleSearchFilterChange = (event) => {
    const { name, value } = event.target;

    setSearchFilter((prev) => ({
      ...prev,
      [name]: name.includes("date") ? formatDate(value) : value,
    }));
  };

  //FORMAT DATE
  const formatDate = (InputDate) => {
    // Convert the Laravel date string to a JavaScript Date object
    const date = new Date(InputDate);
    // Format the date components
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`
};
 
  const Dragable = (data) => {

    const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      return result;
    };

    const onDragEnd = (result) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }
      const reorderedItems = reorder(
        tableData.rows.data,
        result.source.index,
        result.destination.index
      );
  
      setTableData({
        ...tableData,
        rows: {
          ...tableData.rows,
          data: reorderedItems,
        },
      });
      
      const sourceID = (result.destination.index !== undefined && result.destination.index !== null) ? tableData.rows.data[result.destination.index].id : null;
      const destinationID = (result.source.index !== undefined && result.source.index !== null) ? tableData.rows.data[result.source.index].id : null;

      saveRecord("/user/record/reorder", {source: sourceID, destination: destinationID, type: dragableType, source_type:SourceType}).then((response) => {
        // if(response.status === 200) {
        //   //do something
        // }
      });
    };

    return (
      <Box
      mb={5}
      >
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <ul {...provided.droppableProps} ref={provided.innerRef}>
              {tableData.rows.data.map((item, index) => (
                <Draggable
                  key={item.id.toString()}
                  draggableId={item.id.toString()}
                  index={index}
                >
                  {(provided) => (
                    <li
                      className="data-card-container"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Box
                      className={"data-card-item "+ (item.status ? item.status : "") +" "+(item.current_status ? item.current_status : "")}
                      display={{ sm: "block", lg: "flex" }}
                      justifyContent="space-between"
                      alignItems="center"
                      >
                        <Box
                        display="flex"
                        >
                          {checkbox && 
                          <Checkbox
                          me={5}
                            key = {index}
                            onChange={
                              (e) => {
                                handleCheckboxChange(e.target.checked, item.id)
                                item.checked = e.target.checked;
                              }
                            }
                            isChecked={item.checked}
                            />
                          }
                          {typeof tableContent === 'function' && tableContent(item)}
                        </Box>
                        <Box>
                          {tableMenu && (
                              <TableMenu menuItems={tableMenu.map(menu => ({
                                ...menu,
                                title: typeof menu.title === 'function' ? menu.title(item) : menu.title,
                                icon: typeof menu.icon === 'function' ? menu.icon(item) : menu.icon,
                            }))} row={item} />
                          )}
                        </Box>
                      </Box>
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
      </Box>
    );
  }
  
  const CardDesign = (layout) => {
    //DEFAULT DESIGN
    return Dragable();
  };

  return (
    <Card
      direction="column"
      w="100%"
      p="0"
      borderRadius="0"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      {title || menuItems || buttons ? (
        <Flex
          justify="space-between"
          mb={5}
          align="center"
          justifyContent={alignText}
        >
          {title && (
            <Text
              color="secondaryGray.900"
              fontSize="22px"
              fontWeight="700"
              lineHeight="100%"
            >
              {title}
            </Text>
          ) }
          <Flex>
            {buttons &&
              buttons.map((button, index) => {
                return (
                  <Button
                    variant="outline"
                    colorScheme="brand"
                    fontWeight="light"
                    leftIcon={button.icon}
                    _hover={{ bg: "brand.500", color: "white" }}
                    transition="background-color 0.2s linear"
                    onClick={button.handle}
                    me="10px"
                  >
                    {button.title}
                  </Button>
                );
              })}
            {menuItems && <TableMenu menuItems={menuItems} />}
          </Flex>
        </Flex>
      ) : null}

      <Flex justify="space-between" align="center" mb="20px">
        <Flex 
        w="50%" maxW="325px" justify="space-between" align="center"
        >
          {search && (
            <InputGroup size="md">
              <Input
              pr="4.5rem"
              type="text"
              placeholder="search..."
              borderRadius="16px"
              _focus={{ borderColor: "gray.300" }}
              onChange={(e) => handleSearch(e.target.value)}
            />
            <InputRightElement width="2.5rem">
              <Icon as={MdOutlineSearch} color="gray.300" fontSize="22px"  />
            </InputRightElement>
          </InputGroup>  
          ) }
        </Flex>
        <Flex justify="space-between" align="center">
          {checkboxAction
            ? Object.keys(checkboxMenu).map((key, index) => {
                return (
                  <Button
                    leftIcon={checkboxMenu[key].icon}
                    variant="outline"
                    me={2}
                    fontWeight="light"
                    colorScheme={checkboxMenu[key].color}
                    onClick={() => checkboxMenu[key].handle(checked)}
                    key={index}
                  >
                    {checkboxMenu[key].name}
                  </Button>
                );
              })
            : null}
          {checkboxAction ? (
            <Button
              leftIcon={<MdOutlineLoop />}
              variant="outline"
              me={2}
              colorScheme="gray"
              onClick={handleClear()}
              fontWeight="light"
            >
              Clear
            </Button>
          ) : null}
        </Flex>
      </Flex>

      <Box
        className="filter-container"
        mb={5}
        >
          {tableData.filters && tableData.filters.map((filter, index) => {
            return (
              <Box
              key={index}
              me={5}
              className="filter-item"
              >
                <FormControl>
                  {filter.type === "select" && (
                    <Select
                      _focus={{
                        borderColor: "gray.300",
                      }}
                      name={filter.name}
                      onChange={handleFilterChange}
                    >
                      {filter.options.map((option, index) => {
                        return (
                          <option key={index} value={option.value}>
                            {option.label}
                          </option>
                        );
                      })}
                    </Select>
                  )}
                  {filter.type === "search" && (
                    <InputGroup size="md">
                      {searchFilter[filter.name] && searchFilter[filter.name].length > 0 && (
                        <InputLeftElement className="filter-search-icon">
                          <Icon 
                            as={MdClose} 
                            fontSize="22px" 
                            onClick={() => {
                              setSearchFilter(prevState => {
                                const newState = { ...prevState };
                                delete newState[filter.name];
                                return newState;
                              });
                              setContentFilter(prevState => {
                                const newState = { ...prevState };
                                delete newState[filter.name];
                                return newState;
                              });
                            }} 
                            />
                        </InputLeftElement>
                      )}
                      <Input
                        className="filter-search-input"
                        type="text"
                        placeholder={filter.placeholder}
                        borderRadius="16px"
                        _focus={{ borderColor: "blue.500" }}
                        name={filter.name}
                        onChange={handleSearchFilterChange}
                        value={searchFilter[filter.name] ? searchFilter[filter.name] : ""}
                      />
                      <InputRightElement className="filter-search-icon">
                        <Icon
                          as={filter.icon=="person" ? MdPersonSearch : MdOutlineManageSearch}
                          fontSize="22px"
                          onClick={() =>
                            setContentFilter({
                              ...contentFilter,
                              [filter.name]: searchFilter[filter.name],
                            })
                          }
                        />
                      </InputRightElement>
                    </InputGroup>
                  )}
                  {["date","datetime-local"].includes(filter.type) && ( 
                    <Input
                      type={filter.type}
                      name={filter.name}
                      onChange={handleFilterChange}
                    />
                  )}
                </FormControl>
              </Box>
            );
          })}
        </Box>

      {contentLoading === false && CardDesign()}

      <Flex justifyContent="center">
        {contentLoading === true ? (
          <BeatLoader
            loading="true"
            size={15}
            aria-label="Loading Spinner"
            color="#422CFA"
          />
        ) : totalCount === 0 ? (
          <Text width="100%" textAlign="center" color="gray.400">
            No records found
          </Text>
        ) : null}
      </Flex>

      {totalCount > 0 && (
        <Flex justify="space-between" align="center">
          <Flex w="50%" align="left">
            <FormControl maxWidth="80px" me={5}>
              <Select
                _focus={{
                  borderColor: "gray.300",
                }}
                defaultValue={perPage}
                onChange={(e) => handlePerPageChange(e.target.value)}
              >
                <option>10</option>
                <option>25</option>
                <option>50</option>
                <option>100</option>
                <option>500</option>
              </Select>
            </FormControl>
            <Text alignContent="center">
              Showing {currentPage === 1 ? 1 : (currentPage - 1) * perPage + 1}{" "}
              to{" "}
              {currentPage * perPage > totalCount
                ? totalCount
                : currentPage * perPage}{" "}
              of {totalCount} records
            </Text>
          </Flex>
          <Flex justify="space-between" align="center">
            {tableData.rows.links && perPage <= totalCount
              ? Object.entries(tableData.rows.links).map(([key, value]) => {
                  if (
                    value.label === "&laquo; Previous" ||
                    value.label === "Next &raquo;"
                  )
                    return null;
                  return (
                    <Button
                      variant={value.active ? "solid" : "outline"}
                      colorScheme={value.active ? "brand" : "gray"}
                      size="sm"
                      me={2}
                      key={key}
                      onClick={() => handlePageChange(value.label)}
                    >
                      {value.label}
                    </Button>
                  );
                })
              : null}
          </Flex>
        </Flex>
      )}
    </Card>
  );
}