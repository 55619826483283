import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdPeople,
  MdHome,
  MdApartment,
  MdManageAccounts,
  MdHouseSiding,
} from "react-icons/md";

// User Imports
import MainDashboard from "views/user/default";
import UserSettings from "views/user/settings";
import UserManagement from "views/user/user-management/main";
import PropertyManagement from "views/user/property-management/main";
import PropertyBoard from "views/user/property-management/view/global";
import PropertyDetail from "views/user/property-management/view/main";

// Auth Imports
import SignInCentered from "views/auth/signIn";

const routes = [
  {
    type: "sidebarItem",
    name: "Dashboard",
    roles: ["admin","office","operation","maintenance","cleaner"],
    layout: "/user",
    path: "/dashboard",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: MainDashboard,
  },
  {
    type: "sidebarItem",
    name: "Team Management",
    roles: ["admin"],
    layout: "/user",
    path: "/team/management",
    icon: <Icon as={MdPeople} width='20px' height='20px' color='inherit' />,
    component: UserManagement,
  },
  {
    type: "sidebarItem",
    name: "Property Management",
    roles: ["admin","office"],
    layout: "/user",
    path: "/property/management",
    icon: <Icon as={MdHouseSiding} width='20px' height='20px' color='inherit' />,
    component: PropertyManagement,
  },
  {
    type: "sidebarItem",
    name: "Property Board",
    layout: "/user",
    path: "/property/board",
    icon: <Icon as={MdApartment} width='20px' height='20px' color='inherit'/>,
    roles: ["admin","office","operation","maintenance","cleaner"],
    component: PropertyBoard,
  },
  {
    type: "sidebarItem",
    name: "Account Settings",
    layout: "/user",
    path: "/settings",
    roles: ["admin", "office", "operation", "maintenance","cleaner"],
    class:"last-item",
    icon: <Icon as={MdManageAccounts} width='20px' height='20px' color='inherit' />,
    component: UserSettings,
  },
  {
    type: "independentItem",
    name: "Login",
    layout: "/auth",
    path: "/login",
    roles: ["admin"],
    component: SignInCentered,
  },
  {
    type: "independentItem",
    name: "Settings",
    layout: "/user",
    path: "/settings",
    roles: ["admin"],
    component: UserSettings,
  },
  {
    type: "independentItem",
    name: "Property Detail",
    layout: "/user",
    roles: ["admin", "office", "operation", "maintenance"],
    path: "/property/management/view/:id",
    component: PropertyDetail,
  },
];

export default routes;
